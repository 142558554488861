/* ** */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

*{
  font-family: "Lato", sans-serif;
}
/***/
.right-button{
  text-align: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
nav.navbar.navbar-expand-md.navbar-light.bg-light{
  padding: 30px 50px;
}
.navbar .row {
  justify-content: center;
  align-items: center;
  width: 100%;
}

.navbar .navbar-nav{
  align-items: center;
  gap: 2px;
}

.navbar .guidstar{
  display: flex;
}
.navbar  li.nav-item {
  font-size: 1.5rem;
}
.navbar  li.nav-item {
  padding: 14px 20px;
}
.navbar  li.nav-item a.nav-link{
  color: #000;
  padding: 0;
}
.card {
  border: none !important;
}
.gap.p-1.col-sm-2 {
  display: flex;
  justify-content: space-around;
}
.mobile-btn.mb-nav-link {
  color: #fff;
  background-color: #cc1b00;
  font-family: Lato, sans-serif;
  font-weight: 700;
  display: none;
}
.collection-wrapper.row{
  overflow: hidden;
  /* margin-right: 0; */
}
/***********/
.collection-wrapper {
  z-index: 3;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.collection-list {
  width: 100%;
  height: 100%;
  position: relative;
}
.collection-item {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.html-embed {
  width: 100%;
  height: 100%;
  font-size: 1.5vw;
}
.link-block-3.map_dot {
  z-index: 20;
  justify-content: flex-end;
  align-items: center;
  margin-top: 11.3em;
  margin-left: 8.6em;
  display: flex;
}
.link-block-3 {
  font-family: Lato, sans-serif;
  position: absolute;
  top: 0%;  
  bottom: auto;
  left: 0%;
  right: auto;
}
.w-embed:after {
  clear: both;
}
.w-embed:before, .w-embed:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.subheading.top-news{
  font-size: 24px;
}

.paragraph-68{
  text-align: center;
}

img.image-53 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer_main.row{
  margin-top: 80px !important;
}

.paragraph-3.map_location {
  color: #000;
  text-align: right;
  white-space: nowrap;
  -webkit-text-fill-color: inherit;
  background-clip: border-box;
  margin-right: 2.5em;
  font-size: 1em;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}
.paragraph-3 {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}
.map_circle {
  width: 1em;
  height: 1em;
  background-color: #cc1b00;
  border-radius: 100vw;
  justify-content: center;
  align-items: center;
  font-size: .7em;
  line-height: .5em;
  display: flex;
  position: relative;
}
.map_fill {
  z-index: -1;
  width: 1.5em;
  height: 1.5em;
  min-height: 1.5em;
  min-width: 1.5em;
  background-color: #2f39fc;
  border-radius: 100vw;
  position: relative;
}
.bold-text-8 {
  color: #cc1b00;
}
.rounded-link {
  color: #fff;
  background-color: #cc1b00;
  border-radius: 9px;
  text-decoration: none;
  flex: 0 auto;
  align-self: center;
  align-items: center;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  padding: 9px 15px;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
div.gnp_trb {
  width: 125px;
}
  div.gnp_trb br { display:none; }
  div.gnp_trb span.gnp_lb { display:block;height:17px;text-align:center;color:#0009A8!important;}
  div.gnp_trb span.gnp_lb a{
    font-family:'HelveticaNeue-Light','Helvetica Neue Light','Helvetica Neue',Helvetica,Arial;
    display:inline-block;
    font-weight:bold;
    font-size:13px;
    color:#0009A8!important;
  }
/***/
.button {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #1a1b1f;
  border-radius: 0;
  padding: 12px 25px;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
  transition: background-color .4s, opacity .4s, color .4s;
}

button.scrollbtn {
  color: #0645ad;
    text-decoration: underline;
    transition: opacity .2s;
    border: none;
    background: transparent;
}
.clr-btn {
  background-color: #cc1b00;
  border-radius: 14px;
  font-weight: 700;
}
.button-3 {
  display: none;
}
.button.cc-contact-us {
  z-index: 5;
  background-color: #cc1b00;
  border-radius: 14px;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  position: relative;
}

.donate-btn {
  background-color: #cc1b00;
  border-radius: 14px;
  font-size: 16px;
  padding: 12px 25px;
  font-weight: 700;
  color: white;
  text-decoration: none;
  letter-spacing: 2px;
  position: relative;
}

.download-button {
  text-decoration: none;
  width: 8em;
  background-color: #1f3a8a;
  border-radius: 10px;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 15px;
  margin-left: 0;
  display: flex;
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  border: 0;
  padding: 9px 15px;
}
.download-button:hover {
  color: #32343a;
}
.search {
  width: auto;
  max-width: none;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0;
  /* display: flex; */
}
.field-label {
  align-items: center;
  margin-bottom: 2px;
  padding-right: 15px;
  /* display: none; */
}
label {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}
.download-button.search-button:hover {
  color: #32343a;
  background-color: #1f3a8a !important;
  color: #fff !important;
  border-color: none;
  padding: 10px 20px;
}
.download-button.search-button {
  margin-bottom: 15px;
  /* margin-left: 15px; */
  margin-top: 15px;
  background-color: #1f3a8a;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
}

.mb-nav-link.nav-item{
  display: none;
}
.text-block {
  width: auto;
  max-width: 10rem;
  flex: 0 auto;
  font-family: Lato, sans-serif;
  font-size: 12px;
  line-height: 21px;
}

.multi-column{
  column-count: 3;
}
.songs-divs {
  height: 20rem;
  max-width: none;
  overflow: scroll;
  width: 93%;
}
.col-sm-12.collection-item-song {
  max-width: none;
  min-width: 30rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-family: Lato, sans-serif;
  display: flex;
}
.download-button.play-combo {
  width: 10em;
  align-self: auto;
  align-items: center;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 15px;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  display: flex;
}
.rich-text-u figure {
  max-width: 60%;
  position: relative;
}
.muli-colmn-tw{
  column-count: 2;
}

.button_text .button{
  padding: 20px 25px !important;
}

.button_text{
  align-items: center;
}

.multi-column.collage_gallery .image-card{
   cursor: pointer;
}
.CardElement {
  display: block;
  padding: 10px 14px;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  background-color: white;
}

.CardElement--focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.CardElement--invalid {
  border-color: #dc3545;
}
.payment-form .col-sm-6,  .payment-form .col-sm-12 {
  padding: 0;
}
.payment-form input.form-control, .payment-form select.form-select {
  border-radius: 0;
  border: 1px solid #d1d1d1;
  border-bottom: none;
  line-height: 2;
}
.payment-form input.lastfield{
  border-bottom: 1px solid #d1d1d1;
}
.payment-form .form-control:focus, .payment-form .form-select:focus{
  box-shadow: none;
}
.payment-form .form-control::placeholder {
  color: rgba(133, 133, 133, 0.836);
  opacity: 1;
}
.search .search-input::placeholder {
  color: rgba(133, 133, 133, 0.836);
}
input.red-border.form-control, input.red-border.form-select {
  border: 1px solid #cc1b00;
}
button.m-2.donate-btn.btn.btn-secondary {
  background: #cc1b00;
  border: none;
  padding: 10px 25px;
}
h1.mt-4.mb-4.fs-1 {
  font-size: 48px !important;
  line-height: 62px !important;
}
.united_state_li li{
  margin-bottom: 10px;
}
.united_state_li li a:hover {
  color: #32343a;
}
.link_hov a:hover {
  color: #32343a;
}
.educator_size{
  font-size: 36px !important;
  line-height: 50px !important;
}
.d-flex.justify-content-center.align-items-center.flex-wrap a:hover {
  color: #32343a;
}
.h1.mt-4.mb-4.fs-1.fw-bold {
  font-size: 48px !important;
  line-height: 62px !important;
}
.text_font_line {
  font-size: 44px !important;
  line-height: 62px !important;
}
.hover-black:hover {
  color: #32343a;
}
.inquires:hover{
  color: #32343a !important;
}
.fs-2 a:hover{
  text-decoration-color: #32343a;
}
.div-block.resource-video {
  justify-content: space-around;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
}
.w-video {
  width: 100%;
  padding: 0;
  /* position: relative; */
}
.w-video iframe, .w-video object, .w-video embed {
  /* border: none; */
  width: 100%;
  /* height: 100%; */
  /* position: absolute; */
  /* top: 0;
  left: 0; */
}
img.logo-image:hover {
  opacity: .8;
}
.educator_page{
  color: #1a1b1f !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
.paragraph-53{
  color: #6b6d72 !important;
  font-family: Montserrat, sans-serif !important;
}
.d-flex.justify-content-center.align-items-center.flex-wrap.row,
.rounded-link,
.d-flex.justify-content-center.align-items-center.flex-wrap.my-2.col-sm-4,
.inquire_font {
  font-family: Montserrat, sans-serif !important;
}
.active {
  border-style: solid;
  border-width: 0px 0px 3px;
  border-color: #cc1b00;
  background-color: var(--white);
  color: var(--black);
  font-size: 1.5rem;
}

.heading-30{
  font-size: 36px;
  line-height: 50px;
  text-align: center;
}
.lineheight li{
  line-height: 28px;
  margin-top: 6px;
}
.whowecss{
  margin-left: 13% !important;
}
.line_height{
  line-height: 28px;
}
.image-27{
  width: 500px;
  height: 600px;

}
@media screen and (min-width: 1280px) {
  .button-3 {
    display: none;
  }
  .button.cc-contact-us {
    display: block;
  }
}
@media screen and (max-width: 991px) {
  .button.cc-contact-us {
    display: none;
  }
  .button-3 {
    color: #fff;
    background-color: #cc1b00;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    display: none;
  }
}
@media screen and (max-width: 768px){
  .donate-btn{
    padding: 12px 10px;
    font-size: 12px;
  }

  .mb-nav-link.nav-item{
    display: block;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .me-auto.navbar-nav{
    gap: 10px;
  }
  div.gnp_trb{
    width: 77px;
  }
  .button.cc-contact-us{
    display: block;
    font-size: 13px;
    padding: 8px 8px;
  }
  .navbar li.nav-item {
    font-size: 1rem;
  }
  .donate-btn{
    padding: 8px 8px;
    font-size: 8px;
  }
  .col-md-2 {
    width: 24.666667% !important;
  }
}
@media screen and (max-width: 767px) {
  nav.navbar.navbar-expand-md.navbar-light.bg-light {
    padding: 20px 0px;
  }
  .mobile-row{
    margin: 0px !important;
  }
  .col-2 {
    width: 100% !important;
  }
  .menu-buttons{
    flex: 1;
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: relative;
    align-self: auto;
    padding: 0px !important;
  }
  .footer-recieve{
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
  }
  .donate-btn{
    display: none;
  }
  .mobile-btn.mb-nav-link{
    display: block;
    text-decoration: none;
    padding: 10px 3rem;
    font-size: 16px;
  }
  button.navbar-toggler {
    border: none;
  }
  .navbar-toggler:focus{
    box-shadow: none !important;
  }
  .multi-column{
    column-count: 2;
  }
  .button-3 {
    display: block;
  }
}
@media screen and (max-width: 479px) {
  .multi-column {
    column-count: 1;
  }
.button-3 {
  display: block;
    color: #fff;
    text-align: center;
    background-color: #cc1b00;
    flex: 0 auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
}
}

.full {
  min-height: 100vh;
}