.thumbnail-gallery {
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-top: 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  padding-bottom: 8px;
  background-color: #ffffff;
}
.carousel-control-next-icon, .carousel-control-prev-icon{
  background-color: #000;
}